import request from '@/utils/request'

export function getList(data) {
	return request({
		url: '/appcompanyhistory/list',
		method: 'get',
		data
	})
}

