<template>
	<div class="about-page">
    <pageHeader></pageHeader>

    <div class="page-banner"></div>
    <div class="page-menu">
      <div class="content-inner">
        <ul>
          <li><router-link to="/about">关于我们</router-link></li>
          <li><router-link to="/development" class="active">发展历程</router-link></li>
        </ul>
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>发展历程</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <div class="session session-develop-1">
      <div class="content-inner" v-loading="loading">
        <div class="session-h1">发展历程</div>
        <div class="session-h2">专业二手车交易运营服务商</div>

        <div class="time-line" v-if="devs.length">
          <el-timeline >
            <el-timeline-item v-for="(dev, index) in devs" :key="index" :timestamp="dev.year+'年'" placement="top">
              <div class="tli-w">
                <div class="tli" v-for="(record, recordIndex) in dev.records" :key="recordIndex">
                  <div class="tli-t">{{ record.occurredTime }}</div>
                  <div class="tli-d">{{ record.occurrence }}</div>
                </div>
              </div>
            </el-timeline-item>

          </el-timeline>
        </div>


      </div>
    </div>

    <pageFooter></pageFooter>


	</div>
</template>

<script>
import pageHeader from "@/components/pageHeader";
import pageFooter from "@/components/pageFooter";
import {getList} from "@/api/development";


export default {
  components: {pageHeader, pageFooter},
	data() {
		return {
			loading: true,
      devs:[],
		};
	},
	mounted() {
    this.getList()
	},
	methods: {
    getList(){
      getList().then(response => {

        let years=[];
        response.data.forEach(log => {
          if(!years[log.occurredTime.substring(0,4)]){
            years[log.occurredTime.substring(0,4)] = {
              year:log.occurredTime.substring(0,4),
              records:[]
            }
          }
          years[log.occurredTime.substring(0,4)].records.push(log);
        });
        years.reverse()
        years.forEach(year => {
          this.devs.push(year);
        });
        this.loading=false

      })
    }
	}
};
</script>

<style lang="scss" scoped>
.page-banner{
  height: 520px;
  background-image: url("../assets/images/banner-2.jpg");
}





</style>
